const refsCommon = {
	// //modals
	acceptBtnCookie: document.getElementById("acceptCookie"),
	deleteBtnCookie: document.getElementById("deleteCookie"),

	// //sections

	// forms
	form: document.getElementById("form"),
};

export default refsCommon;
